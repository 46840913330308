/*
 * Developed by CQ Inversiones SAS. Copyright ©. 2019 - 2024. All rights reserved.
 * Desarrollado por CQ Inversiones SAS. Copyright ©. 2019 - 2024. Todos los derechos reservados.
 */

/*
* Project:      frontend
* Developed by: Jhoana Marcela Rodriguez Fernandez
* Date:         4/3/2024 - 17:03
* Module name:  frontend
* File name:    panel-card.const.ts
* IDE:          WebStorm
*/

import {PanelOptionInterface} from '../../interface';

/**
 * @description
 * Menu option in the customer carousel
 */
export const PANEL_CARD_CUSTOMER: PanelOptionInterface[] = [
  {
    data: {
      level: 1,
      class: 'card_customer',
      description: 'Listar',
    },
    title: 'app.setting.panel.admin.customer.option.customer.title',
    icon: 'users',
    link: 'customers',
  },
  {
    data: {
      level: 2,
      class: 'card_contract',
      description: 'Listar',
    },
    title: 'app.setting.panel.admin.customer.option.contract.title',
    icon: 'file-contract',
    link: 'contracts',
  },
  {
    data: {
      level: 3,
      class: 'card_user',
      description: 'Listar',
    },
    title: 'app.setting.panel.admin.customer.option.user.title',
    icon: 'user',
    link: 'users',
  },
  {
    data: {
      level: 4,
      class: 'card_groups',
      description: 'Listar',
    },
    title: 'app.setting.panel.admin.customer.option.group.title',
    icon: 'users-gear',
    link: 'groups',
  },
  {
    data: {
      level: 4,
      class: 'card_contact',
      description: 'Listar',
    },
    title: 'app.setting.panel.admin.customer.option.contacts.title',
    icon: 'messages',
    link: 'contacts',
  },
  {
    data: {
      level: 3,
      class: 'card_location',
      description: 'Listar',
    },
    title: 'app.setting.panel.admin.device.option.location.title',
    icon: 'location-dot',
    link: 'locations',
  },

];

/**
 * @description
 * Menu option in the device carousel
 */
export const PANEL_CARD_DEVICES: PanelOptionInterface[] = [
  {
    data: {
      level: 4,
      class: 'card_device_customization',
      description: 'Listar',
    },
    title: 'app.setting.panel.admin.device.option.deviceCustomization.title',
    icon: 'pen-to-square',
    link: 'device-customization',
  },
  {
    data: {
      level: 4,
      class: 'card_triggers',
      description: 'Listar',
    },
    title: 'app.setting.panel.admin.device.option.triggers.title',
    icon: 'bell-on',
    link: 'triggers',
  }
];

/**
 * @description
 * Menu option in the system carousel
 */
export const PANEL_CARD_SYSTEM: PanelOptionInterface[] = [
  {
    data: {
      level: 1,
      class: 'card_parameters',
      description: 'Listar',
    },
    title: 'app.setting.panel.admin.system.option.parameters.title',
    icon: 'list-tree',
    link: 'parameters',
  },
  {
    data: {
      level: 1,
      class: 'card_commands',
      description: 'Listar',
    },
    title: 'app.setting.panel.admin.system.option.commands.title',
    icon: 'square-terminal',
    link: 'commands',
  },
  {
    data: {
      level: 1,
      class: 'card_icons',
      description: 'Listar',
    },
    title: 'app.setting.panel.admin.system.option.icons.title',
    icon: 'symbols',
    link: '/icons',
  },
  {
    data: {
      level: 0,
      class: 'card_sensor',
      description: 'Listar',
    },
    title: 'app.setting.panel.admin.system.option.sensor.title',
    icon: 'sensor-on',
    link: 'sensors',
  },
  {
    data: {
      level: 0,
      class: 'card_model',
      description: 'Listar',
    },
    title: 'app.setting.panel.admin.device.option.models.title',
    icon: 'laptop-file',
    link: 'device-model',
  },
  {
    data: {
      level: 0,
      class: 'card_device',
      description: 'Listar',
    },
    title: 'app.setting.panel.admin.device.option.devices.title',
    icon: 'computer-classic',
    link: 'devices',
  }
];

